const rem = {
  xs: 18,
  sm: 18,
  md: 18,
  lg: 19,
  xl: 20,
  xxl: 28,
}

function onRegister(){
  const registerDiv = document.querySelector('#register .board');
  registerDiv.setAttribute('data-state', '2');
  syncBoardHeight();
}

function resetForm(){
  const registerDiv = document.querySelector('#register .board');
  registerDiv.setAttribute('data-state', '1');
  syncBoardHeight();
}

function showThankyouPage(){
  const registerDiv = document.querySelector('#register .board');
  registerDiv.setAttribute('data-state', '3');
  syncBoardHeight();
}

function showEmailDuplicatedPage(){
  const registerDiv = document.querySelector('#register .board');
  registerDiv.setAttribute('data-state', '4');
  syncBoardHeight();
}

let siid;
function syncBoardHeight(){
  clearTimeout(siid);
  siid = setTimeout(()=>{
    const boards = document.querySelectorAll('.modal .board');
    boards.forEach(board => {
      const state = board.getAttribute('data-state');
      const h = board.querySelector('.state-'+ state).offsetHeight;
      board.style.height = (h <=0 ) ? 'auto' : (h + 'px');
    })
  }, 500)
}

function isEmail(str){
  return /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/i.test(str)
}

function invalid(msg){
  console.log(msg)
}

function registerFormSubmit(e){
  e.stopPropagation();
  e.preventDefault();

  const form = document.querySelector('#form form');
  const email = form.querySelector('input[name=":email"]').value;
  //validate
  if(!isEmail(email)){
    return invalid('invalid email');
  }

  form.classList.add('sending');
  const formData = new FormData(form);
  const formBody = new URLSearchParams(formData).toString();

  //ajax submit
  //old browser not support fetch
  if(!fetch || !formBody || (formBody === "null")){
    form.submit();
    onRegister();
    return;
  }

  const postTarget = form.getAttribute('action');

  fetch(postTarget, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : formBody+'&fetch=1'
  })
    .then(response => response.text())
    .then(data => {
      //replace debug info
      const raw = data.replace(/}<div [^$]+/i, '}');
      const result = JSON.parse(raw);
      if(result.error && result.type === "REGISTER_EMAIL_ALREADY_ACTIVATE"){
        showEmailDuplicatedPage();
        return;
      }
      showThankyouPage();
    })
    .catch(error => {
      form.classList.remove('sending');
      document.getElementById('error').innerHTML = error.toString();
    });

  onRegister();
}

function loginFormSubmit(e){
  e.stopPropagation();
  e.preventDefault();

  const form = document.querySelector('#form-login form');
  const board = document.querySelector('#modal-login .board');

  form.querySelector('.error').innerHTML = "";
  board.setAttribute('data-state', "2");

  const formData = new FormData(form);
  const formBody = new URLSearchParams(formData).toString();

  const postTarget = form.getAttribute('action') + '.json';
  fetch(postTarget, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : formBody+'&fetch=1'
  })
    .then(response => response.text())
    .then(data => {
      //replace debug info
      const raw = data.replace(/}<div [^$]+/i, '}');
      const result = JSON.parse(raw);

      if( result.error === true){
        if(result.payload.message === 'EXCEED'){
          const lead = result.payload.lead;
          const sign = result.payload.sign;
          const form = document.getElementById('form-exceed');
          const language = form.getAttribute('data-language');
          form.setAttribute('action', `/${language}/exceed/request/${lead}/${sign}`);
          board.setAttribute('data-state', "3");
        }

        if(result.payload.message === 'USER_NOT_FOUND'){
          form.querySelector('.error').innerHTML = 'invalid username or password';
          board.setAttribute('data-state', "1");
        }
        return;
      }

      if(result.payload.redirect){
        window.location = result.payload.redirect;
      }
    })
    .catch(error => {
      form.querySelector('.error').innerHTML = error.toString();
      board.setAttribute('data-state', "1");
    });
}

function exceedFormSubmit(e){
  e.stopPropagation();
  e.preventDefault();

  const form = document.querySelector('#form-exceed');
  const board = document.querySelector('#modal-login .board');

  form.querySelector('.error').innerHTML = "";
  board.setAttribute('data-state', "2");

  const formData = new FormData(form);
  const formBody = new URLSearchParams(formData).toString();

  const postTarget = form.getAttribute('action') + '-.json';

  fetch(postTarget, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : formBody+'&fetch=1'
  })
    .then(response => response.text())
    .then(data => {
      //replace debug info
      const raw = data.replace(/}<div [^$]+/i, '}');
      const result = JSON.parse(raw);

      board.setAttribute('data-state', "4");
    })
    .catch(error => {
      form.querySelector('.error').innerHTML = error.toString();
      board.setAttribute('data-state', "1");
    });
}

function contactFormSubmit(e){
  e.stopPropagation();
  e.preventDefault();

  const board = document.querySelector('#modal-contact .board');
  const form = document.querySelector('#form-contact form');
  const formData = new FormData(form);
  const formBody = new URLSearchParams(formData).toString();

  //ajax submit
  fetch(form.getAttribute('action'), {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : formBody+'&fetch=1'
  })
    .then(response => response.text())
    .then(data => {
      //replace debug info
      const raw = data.replace(/}<div [^$]+/i, '}');
      const result = JSON.parse(raw);

      board.setAttribute('data-state', '3');
      syncBoardHeight();
    })
    .catch(error => {
      form.querySelector('.error').innerHTML = error.toString();
      board.setAttribute('data-state', '1');
    });

  board.setAttribute('data-state', '2');
}

function forgotPasswordFormSubmit(e){
  e.stopPropagation();
  e.preventDefault();

  const board = document.querySelector('#modal-forgot-password .board');
  const form = document.querySelector('#form-forgot-password form');
  const formData = new FormData(form);
  const formBody = new URLSearchParams(formData).toString();

  //ajax submit
  fetch(form.getAttribute('action'), {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body : formBody+'&fetch=1'
  })
    .then(response => response.text())
    .then(data => {
      //replace debug info
      const raw = data.replace(/}<div [^$]+/i, '}');
      const result = JSON.parse(raw);
      if(result.error){

        form.querySelector('.error').innerHTML = result.payload.text;
        board.setAttribute('data-state', '1');
        return;
      }

      board.setAttribute('data-state', '3');
      syncBoardHeight();
    })
    .catch(error => {
      form.querySelector('.error').innerHTML = error.toString();
      board.setAttribute('data-state', '1');
    });

  board.setAttribute('data-state', '2');
}

(fn => (document.readyState !== 'loading') ? fn() : document.addEventListener('DOMContentLoaded', fn))(()=>{
  const form = document.querySelector('#form form');
  if(form)form.addEventListener('submit', registerFormSubmit);

  const formLogin = document.querySelector('#form-login form');
  if(formLogin)formLogin.addEventListener('submit', loginFormSubmit);

  const formExceed = document.querySelector('#form-exceed');
  if(formExceed)formExceed.addEventListener('submit', exceedFormSubmit);

  const formContact = document.querySelector('#form-contact form');
  if(formContact)formContact.addEventListener('submit', contactFormSubmit);

  const formForgotPassword = document.querySelector('#form-forgot-password form');
  if(formForgotPassword)formForgotPassword.addEventListener('submit', forgotPasswordFormSubmit);

  const syncPhone = (input, phoneData) =>{
    //remove non number
    phoneData.value = (document.querySelector('.form-body .iti__selected-dial-code').innerText + input.value.replace(/[^0-9]/g, ''));
    input.value = input.value.replace(/[^0-9]/g, '');
  }

  /* sync phone input with intl tel input*/
  document.querySelectorAll('.intl-tel-input').forEach(
    el => {
      const input = el.querySelector('input[type="tel"]');
      const phoneData = el.querySelector('input[type="hidden"]');
      window.intlTelInput(input, {
        separateDialCode: true,
        initialCountry : input.dataset.ipcountry,
        preferredCountries : ['HK', 'SG', 'MO', 'CN']
      });

      input.addEventListener('change', () => syncPhone(input, phoneData));
      input.addEventListener('keyup', () =>syncPhone(input, phoneData));
    }
  )

  //reset form
  document.querySelectorAll('a[data-target="#register"]').forEach(link =>{
    link.addEventListener('click', ()=>{
      resetForm();
    })
  })

  //forgot password
  const btnForgot = document.getElementById('btn-forgot-password');
  if(btnForgot){
    btnForgot.addEventListener('click', (ev) =>{
      ev.preventDefault();
      ev.stopPropagation();
      $('#modal-login').modal('hide');
      $('#modal-forgot-password').modal('show');
      setTimeout(()=>{
        document.body.classList.add('modal-open');
      }, 500);

    })
  }

  //sync board size when resize window
  window.addEventListener('resize', ()=>{
    syncBoardHeight();
  });

  //register message
  document.querySelectorAll('a[data-toggle="modal"][data-target="#register"]').forEach(el=>{
    el.addEventListener('click', evt =>{
      document.querySelector('#register input[name=":message"]').value = el.getAttribute('data-message');
    })
  })
})
